@use "../functions/functions-fonts" as *;
@use "../mixins/mixins-buttons" as *;

.container-error-page {
  overflow: hidden;
  color: var(--white);
  text-align: center;
  height: calc(100vh - 272px);
  padding-top: pxToRem(40px);

  h1 {
    font-size: pxToRem(32px);
    margin-bottom: 1rem;
  }

  h2 {
    font-family: var(--font-text), sans-serif;
    font-size: pxToRem(18px);
    font-weight: 500;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .container-bg-error-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--black);
      opacity: 0.43;
      z-index: 2;
    }


    img {
      z-index: 1;
    }
  }

  .content {
    z-index: 3;
    position: relative;
  }

  .btn-sm-default {
    @include button(pxToRem(18px), var(--light), var(--primary), var(--primary), var(--light));

    &.btn-go-back {
      margin-top: pxToRem(35px);
      display: inline-flex;
    }
  }

  .container-spaceman {
    max-width: 300px;
    margin: pxToRem(90px) auto 0;
    transform: translate(0, -4rem) rotate(3deg);
    animation: 7s ease-in forwards spinning;
  }

  @keyframes spinning {
    0% {
      transform: translate(0, -4rem) rotate(3deg) scale(1);
    }

    5% {
      transform: translate(0, -4rem) rotate(3deg) scale(1);
    }

    85% {
      transform: translate(-3rem, -8rem) rotate(-360deg) scale(0);
    }

    100% {
      transform: translate(0, -20rem) rotate(-360deg) scale(0);
    }
  }
}

@media (min-width: 768px) {
  .container-error-page {
    height: calc(100vh - 142px);
  }

}

@media (min-width: 992px) {
  .container-error-page {
    height: calc(100vh - 121px);
    padding-top: pxToRem(106px);

    h1 {
      font-size: pxToRem(43px);
    }

    h2 {
      font-size: pxToRem(22px);
    }
  }
}